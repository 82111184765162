import { useEffect, useLayoutEffect, useState } from 'react';
import { Box, DEFAULT_THEME, ScrollArea } from '@mantine/core';
import { ControlledTreeEnvironment, Tree } from 'react-complex-tree';
import { renderers as bpRenderers } from 'react-complex-tree-blueprintjs-renderers';
import { Classes, Collapse, Icon } from '@blueprintjs/core';
import cx from 'clsx';

import { useAppStore } from 'stores/appStore';
import 'react-complex-tree/lib/style-modern.css';
import classes from 'components/ScheduleList/ScheduleList.module.css';
import 'components/ScheduleList/scheduleList.css';
import { faCircleSmall } from '@fortawesome/sharp-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { processBuildList } from './scheduleListUtils';

export function ScheduleList() {
  const [currentScheduleId, setCurrentScheduleId] = useAppStore((state) => [
    state.currentScheduleId,
    state.setCurrentScheduleId,
  ]);
  const [buildsList] = useAppStore((state) => [state.buildsList]);

  const [focusedItem, setFocusedItem] = useState();
  const buildIds = buildsList.map((build) => build.id);
  const [expandedItems, setExpandedItems] = useState(buildIds);
  const [selectedItems, setSelectedItems] = useState(currentScheduleId ? [currentScheduleId] : []);

  useEffect(() => {
    setSelectedItems([`${currentScheduleId}`]);
  }, [currentScheduleId]);

  useLayoutEffect(() => {
    // Hack to add a title to potentially ellipsed items.
    const treeItems = document.querySelectorAll('.bp5-tree-node-label');
    treeItems.forEach((item) => item.setAttribute('title', item.textContent || ''));
  }, []);

  return (
    <Box className={classes.container} w="100%" h="100%">
      <ScrollArea
        w="100%"
        h="100%"
        className={`${classes.scrollArea} schedule-list-container`}
        classNames={{ viewport: classes.viewport }}
      >
        <ControlledTreeEnvironment
          {...bpRenderers}
          renderItem={(props) => (
            <li
              className={cx(
                Classes.TREE_NODE,
                (props.context.isSelected || props.context.isDraggingOver) &&
                  Classes.TREE_NODE_SELECTED
              )}
              {...(props.context.itemContainerWithChildrenProps as any)}
            >
              <div
                className={cx(
                  Classes.TREE_NODE_CONTENT,
                  `${Classes.TREE_NODE_CONTENT}-${props.depth}`
                )}
                {...(props.context.itemContainerWithoutChildrenProps as any)}
                {...(props.context.interactiveElementProps as any)}
              >
                {props.item.isFolder ? (
                  props.arrow
                ) : (
                  <span className={Classes.TREE_NODE_CARET_NONE} />
                )}
                {/* @ts-ignore */}
                {props.item.data.icon !== undefined ? (
                  // @ts-ignore
                  props.item.data.icon === null ? null : (
                    // @ts-ignore
                    <Icon icon={props.item.data.icon} className={Classes.TREE_NODE_ICON} />
                  )
                ) : (
                  (() => {
                    const icon = !props.item.isFolder
                      ? 'document'
                      : props.context.isExpanded
                        ? 'folder-open'
                        : 'folder-close';
                    if (icon === 'document') {
                      let color = DEFAULT_THEME.colors.blue[7];
                      // @ts-ignore - additional prop to surface the rating
                      const score = props.item?.score;
                      if (score) {
                        if (score > 12) {
                          // eslint-disable-next-line prefer-destructuring
                          color = DEFAULT_THEME.colors.green[8];
                        } else if (score < 2) {
                          // eslint-disable-next-line prefer-destructuring
                          color = DEFAULT_THEME.colors.red[5];
                        }
                      }
                      return (
                        <FontAwesomeIcon
                          icon={faCircleSmall}
                          color={color}
                          className={Classes.TREE_NODE_ICON}
                        />
                      );
                    }
                    return <Icon icon={icon} className={Classes.TREE_NODE_ICON} />;
                  })()
                )}
                {props.title}
              </div>
              <div
                className={cx(Classes.COLLAPSE)}
                style={
                  props.context.isExpanded
                    ? {
                        height: 'auto',
                        overflowY: 'visible',
                        transition: 'none 0s ease 0s',
                      }
                    : {}
                }
              >
                <Collapse isOpen={props.context.isExpanded} transitionDuration={0}>
                  {props.children}
                </Collapse>
              </div>
            </li>
          )}
          // Filter out builds that have no results.
          items={processBuildList(buildsList.filter((build) => build.results.length > 0)).items}
          getItemTitle={(item) => item.data}
          canDragAndDrop
          canReorderItems
          canDropOnFolder
          canDropOnNonFolder
          viewState={{
            buildTree: {
              focusedItem,
              expandedItems,
              selectedItems,
            },
          }}
          onExpandItem={(item) => {
            // @ts-ignore
            setExpandedItems([...expandedItems, item.index]);
          }}
          onCollapseItem={(item) => {
            // @ts-ignore
            setExpandedItems(
              expandedItems.filter((expandedItemIndex) => expandedItemIndex !== item.index)
            );
          }}
          onSelectItems={(items) => {
            // @ts-ignore
            if (!items.length) {
              return;
            }
            // @ts-ignore
            if (!buildIds.includes(items[0])) {
              // @ts-ignore
              setCurrentScheduleId(items[0]);
            }
          }}
          // @ts-ignore
          onFocusItem={(item) => setFocusedItem(item.index)}
          // renderItem={(props) => {
          //   const element = createDefaultRenderers(10).renderItem(props);
          //   return element;
          // }}
        >
          <Tree treeId="buildTree" rootItem="root" treeLabel="Tree Example" />
        </ControlledTreeEnvironment>
      </ScrollArea>
    </Box>
  );
}
