/* eslint-disable no-restricted-syntax */
import { TreeItem } from 'react-complex-tree';
import { BuildListEntry } from 'utils/scheduleConsts';

const treeOutputBase: { items: Record<string, TreeItem<string>> } = {
  items: {
    root: {
      index: 'root',
      canMove: true,
      isFolder: true,
      children: ['Fruit', 'Meals', 'Desserts', 'Drinks'],
      data: 'root',
      canRename: true,
    },
  },
};

export const processBuildList = (buildList: BuildListEntry[]): typeof treeOutputBase => {
  const data = { ...treeOutputBase };
  const allBuildIds = buildList.map((build) => build.id);
  data.items.root.children = allBuildIds;
  for (const build of buildList) {
    data.items[build.id] = {
      index: build.id,
      canMove: false,
      isFolder: true,
      children: build.results.map((result) => result.id),
      data: build.name,
      canRename: false,
    };
    for (const schedule of build.results) {
      data.items[schedule.id] = {
        index: schedule.id,
        canMove: false,
        isFolder: false,
        children: undefined,
        data: schedule.name,
        canRename: true,
        // @ts-ignore Additional prop to surface the rating, could be undefined.
        score: schedule.score,
      };
    }
  }
  return data;
};
