/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AnalysisIndex,
  AnalysisTableRow,
  ByeThursIndex,
  ByeThursIndexEntry,
  ConstraintEntry,
  ConstraintSlot,
  ConstraintSlotTranslations,
  ConstraintType,
  DAY_KEYS,
  DAYS_IN_ORDER,
  DayType,
  EyeChartIndex,
  HolidaySlot,
  HolidaySlotTranslations,
  LEGACY_SLOT_KEYS,
  LegacyNetworkKeyToNetworkKeysSimpleMap,
  LegacySlotType,
  MatchupIndex,
  NETWORK_KEYS,
  NETWORK_KEYS_SIMPLE,
  NetworkSummaryIndexSimple,
  NetworkType,
  RawByeThursSummary,
  RawNetworkSummarySimple,
  RawRestSummary,
  RawSchedule,
  RestSummaryEntry,
  RestSummaryIndex,
  SLOTS_IN_ORDER,
  SlotType,
  SummaryHighlightsInfo,
  TEAM_KEYS,
  TeamSummaryIndex,
  TeamType,
  UpgradedLegacyKey,
  UpgradedScheduleEntry,
  ViewershipPredictionsAverageEntry,
  ViewershipPredictionsDataRaw,
  ViewershipPredictionsIndex,
  ViewershipPredictionsRowEntry,
  WEEK_KEYS,
  WeekSummaryIndex,
  WeekType,
  WhereType,
} from './scheduleConsts';

export const getEmptySchedule: () => MatchupIndex[] = () => WEEK_KEYS.map((week) => ({ week }));

export const processRawSchedule = (rawSchedule: RawSchedule): MatchupIndex[] => {
  const retVal = getEmptySchedule();
  Object.entries(rawSchedule).forEach(([networkKey, matchupsArr]) => {
    const [network, day, week, slot] = networkKey.split('.');
    const matchupIndexEntry = retVal.find((entry) => entry.week === Number(week));
    if (!matchupIndexEntry) {
      throw new Error(`No matchupIndexEntry found for week ${week}`);
    }
    matchupsArr.forEach((matchup) => {
      const [team, opponent] = matchup.split('-');
      matchupIndexEntry[team as TeamType] = {
        opponent: opponent as TeamType,
        where: 'home',
        week: Number(week) as WeekType,
        networkKey,
        day,
        network,
        slot,
      };
      matchupIndexEntry[opponent as TeamType] = {
        opponent: team as TeamType,
        where: 'away',
        networkKey,
        week: Number(week) as WeekType,
        day,
        network,
        slot,
      };
    });
  });
  return retVal;
};

const getDateFromWeekAndDay = (week: WeekType, day: DayType) => {
  const startDate = new Date(2024, 8, 5); // September 5, 2024
  const daysInWeek = 7;
  const dayIndex = DAY_KEYS.indexOf(day);

  // Season opener is on a Thursday
  const DAY_OFFSET = DAY_KEYS.indexOf('thu');

  if (dayIndex === -1) {
    throw new Error(`Invalid day type: ${day}`);
  }

  // Calculate the date based on the week and day
  const date = new Date(startDate);
  date.setDate(startDate.getDate() + (week - 1) * daysInWeek + dayIndex - DAY_OFFSET);

  // Format the date as YYYY-MM-DD
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const dayOfMonth = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${dayOfMonth}`;
};

export const upgradeLegacyKey = (legacyKey: string): UpgradedLegacyKey => {
  const [legacyNetworkKey, dayKey, weekKey, slotKey] = legacyKey.split('.');
  const date = getDateFromWeekAndDay(Number(weekKey) as WeekType, dayKey as DayType);
  if (!LegacyNetworkKeyToNetworkKeysSimpleMap[legacyNetworkKey]) {
    // The 'bye' week was introudced by accident and should be skipped.
    if (legacyNetworkKey === 'tbd' || legacyNetworkKey === 'bye') {
      // This case should not happen, but add it in case it does.
      console.log('Unknown entry found', legacyKey);

      return {
        network: undefined,
        day: dayKey as DayType,
        week: Number(weekKey) as WeekType,
        legacy_key: legacyKey,
        slot_type: 'custom',
        slot_name: 'Unknown',
        double_header: false,
        international: false,
        holiday: undefined,
        date,
      };
    }
    throw new Error(`Unknown legacy network key ${legacyNetworkKey}`);
  }
  if (!DAY_KEYS.includes(dayKey as DayType)) {
    throw new Error(`Unknown day key ${dayKey}`);
  }
  if (!LEGACY_SLOT_KEYS.includes(slotKey as LegacySlotType)) {
    throw new Error(`Unknown slot key ${slotKey}`);
  }
  let slotType: SlotType | undefined;
  let doubleHeader = false;
  let international = false;
  let holiday: HolidaySlot | undefined;
  switch (slotKey) {
    case 'e':
      if (legacyNetworkKey === 'cbs') {
        slotType = 'CBS Early';
      } else if (legacyNetworkKey === 'fox') {
        slotType = 'Fox Early';
      }
      break;
    case 'l':
      if (legacyNetworkKey === 'cbs') {
        slotType = 'CBS Late';
      } else if (legacyNetworkKey === 'fox') {
        slotType = 'Fox Late';
      }
      break;
    case 'dh':
      doubleHeader = true;
      // DH uses "late" keys
      if (legacyNetworkKey === 'cbs') {
        slotType = 'CBS Late';
      } else if (legacyNetworkKey === 'fox') {
        slotType = 'Fox Late';
      }
      break;
    case 'prime':
      break;
    case 'int':
      slotType = 'International';
      international = true;
      // Special case for Peacock.
      if (legacyNetworkKey === 'peacock') {
        slotType = 'Peacock';
      }
      break;
    default:
      throw new Error(`Unknown slot key ${legacyKey}`);
  }
  if (!slotType) {
    switch (legacyNetworkKey) {
      case 'nbc':
        slotType = 'NBC';
        break;
      case 'espn':
        slotType = 'ESPN';
        break;
      case 'amz':
        slotType = 'Prime';
        break;
      case 'netflix':
        slotType = 'Netflix';
        break;
      case 'peacock':
        slotType = 'Peacock';
        break;
      case 'nfln':
        slotType = 'NFLN';
        break;
      default:
        throw new Error(`Unknown network key ${legacyNetworkKey}`);
    }
  }
  if (dayKey === 'thu' && weekKey === '13') {
    holiday = HolidaySlot.TGIV;
  } else if (dayKey === 'fri' && weekKey === '13') {
    holiday = HolidaySlot.BLACK_FRIDAY;
  } else if (date === '2024-12-25') {
    holiday = HolidaySlot.CHRISTMAS;
  }

  return {
    network: LegacyNetworkKeyToNetworkKeysSimpleMap[legacyNetworkKey],
    day: dayKey as DayType,
    week: Number(weekKey) as WeekType,
    legacy_key: legacyKey,
    slot_type: slotType,
    slot_name: 'legacy',
    date,
    double_header: doubleHeader,
    international,
    holiday,
  };
};

export const upgradeRawSchedule = (rawSchedule: RawSchedule): UpgradedScheduleEntry[] => {
  const retVal: UpgradedScheduleEntry[] = [];
  for (const [legacyKey, matchupsArr] of Object.entries(rawSchedule)) {
    const upgradedKey = upgradeLegacyKey(legacyKey);
    matchupsArr.forEach((matchup) => {
      const [away, home] = matchup.split('-');
      retVal.push({
        ...upgradedKey,
        home: home as TeamType,
        away: away as TeamType,
      });
    });
  }
  // Uncomment this to see the whole upgraded schedule in the console.
  // console.log(retVal);
  return retVal;
};

export const getEmptyEyeChartIndex: () => EyeChartIndex = () => WEEK_KEYS.map((week) => ({ week }));

export const getEyeChartIndex: (schedule: UpgradedScheduleEntry[]) => EyeChartIndex = (
  schedule
) => {
  const retVal: EyeChartIndex = getEmptyEyeChartIndex();
  schedule.forEach((entry) => {
    const eyeChartIndexRow = retVal.find((row) => row.week === entry.week);
    if (!eyeChartIndexRow) {
      throw new Error(`No eyeChartIndexRow found for week ${entry.week}`);
    }
    eyeChartIndexRow[entry.home] = {
      opponent: entry.away,
      date: entry.date,
      day: entry.day,
      where: 'away',
      network: entry.network,
      holiday: entry.holiday,
      legacy_key: entry.legacy_key,
      double_header: entry.double_header,
      slot_type: entry.slot_type,
      week: entry.week,
    };
    eyeChartIndexRow[entry.away] = {
      opponent: entry.home,
      date: entry.date,
      day: entry.day,
      where: 'home',
      network: entry.network,
      holiday: entry.holiday,
      legacy_key: entry.legacy_key,
      double_header: entry.double_header,
      slot_type: entry.slot_type,
      week: entry.week,
    };
  });
  return retVal;
};

export const getSchedule: (id: string) => Promise<MatchupIndex[]> = async (id: string) => {
  const { default: data }: { default: RawSchedule } = await import(
    `../data/schedules/${id}/schedule.json`
  );
  return processRawSchedule(data);
};

export const getEmptyNetworkSummary: () => NetworkSummaryIndexSimple[] = () =>
  Array.from(NETWORK_KEYS_SIMPLE, (key) => ({ network: key }));

export const getEmptyByeThursSummary: () => ByeThursIndex = () => {
  const retVal: ByeThursIndex = [];
  ['bye', 'thurs/fri'].forEach((type) => {
    const temp: ByeThursIndexEntry = { type: type as 'bye' | 'thurs/fri' };
    TEAM_KEYS.forEach((team) => {
      temp[team] = [];
    });
    retVal.push(temp);
  });
  return retVal;
};

export const getEmptyRestSummary: () => RestSummaryIndex = () => {
  const retVal: RestSummaryIndex = [];
  [...WEEK_KEYS, 'total'].forEach((week) => {
    const temp: RestSummaryEntry = { week: week as WeekType | 'total' };
    TEAM_KEYS.forEach((team) => {
      temp[team] = 0;
    });
    retVal.push(temp);
  });
  return retVal;
};

export const processRawNetworkSummarySimple = (
  rawNetworkSummary: RawNetworkSummarySimple
): NetworkSummaryIndexSimple[] => {
  const retVal = getEmptyNetworkSummary();
  Object.entries(rawNetworkSummary).forEach(([teamKey, networkEntries]) => {
    Object.entries(networkEntries).forEach(([networkKey, rating]) => {
      const networkSummaryIndexEntry = retVal.find((entry) => entry.network === networkKey);
      if (!networkSummaryIndexEntry) {
        throw new Error(`No networkSummaryIndexEntrySimple found for network ${networkKey}`);
      }
      networkSummaryIndexEntry[teamKey as TeamType] = rating;
    });
  });
  return retVal;
};

export const getNetworkSummmary: (id: string) => Promise<NetworkSummaryIndexSimple[]> = async (
  id: string
) => {
  const { default: data }: { default: RawNetworkSummarySimple } = await import(
    `../data/schedules/${id}/summary_network_teams.json`
  );
  return processRawNetworkSummarySimple(data);
};

export const processRawByeThursSummary = (
  rawByeThursSummary: RawByeThursSummary
): ByeThursIndex => {
  const retVal = getEmptyByeThursSummary();
  Object.entries(rawByeThursSummary).forEach(([teamKey, entries]) => {
    const byeThursEntry = retVal.find((entry) => entry.type === 'bye');
    if (!byeThursEntry) {
      throw new Error('No byeThursEntry found for type bye');
    }
    byeThursEntry[teamKey as TeamType] = entries.bye;
    const thursFriEntry = retVal.find((entry) => entry.type === 'thurs/fri');
    if (!thursFriEntry) {
      throw new Error('No thursFriEntry found for type thurs/fri');
    }
    thursFriEntry[teamKey as TeamType] = entries['thurs/fri'];
  });
  return retVal;
};

export const getByeThursSummary: (id: string) => Promise<ByeThursIndex> = async (id: string) => {
  try {
    const { default: data }: { default: RawByeThursSummary } = await import(
      `../data/schedules/${id}/summary_bye_thurs.json`
    );
    return processRawByeThursSummary(data);
  } catch (e) {
    return getEmptyByeThursSummary();
  }
};

export const processRawRestSummary = (rawRestSummary: RawRestSummary): RestSummaryIndex => {
  const retVal = getEmptyRestSummary();
  Object.entries(rawRestSummary).forEach(([teamKey, restValues]) => {
    Object.entries(restValues).forEach(([week, value]) => {
      const restSummaryEntry = retVal.find((entry) => String(entry.week) === week);
      if (!restSummaryEntry) {
        throw new Error(`No restSummaryEntry found for week ${week}`);
      }
      restSummaryEntry[teamKey as TeamType] = value;
    });
  });

  return retVal;
};

export const getRestSummary: (id: string) => Promise<RestSummaryIndex> = async (id: string) => {
  try {
    const { default: data }: { default: RawRestSummary } = await import(
      `../data/schedules/${id}/summary_rest.json`
    );
    return processRawRestSummary(data);
  } catch (e) {
    return getEmptyRestSummary();
  }
};

export const getSummaryHighlights: (id: string) => Promise<SummaryHighlightsInfo> = async (
  id: string
) => {
  const { default: data }: { default: SummaryHighlightsInfo } = await import(
    `../data/schedules/${id}/summary_highlights.json`
  );
  return data;
};

export const getTeamSummary: (id: string) => Promise<TeamSummaryIndex> = async (id: string) => {
  const { default: data }: { default: TeamSummaryIndex } = await import(
    `../data/schedules/${id}/summary_teams.json`
  );
  return data;
};

export const processViewershipPredictionsDataRaw = (
  viewershipPredictionsRaw: ViewershipPredictionsDataRaw
) => {
  const viewershipIndex: ViewershipPredictionsRowEntry[] = WEEK_KEYS.filter(
    (week) => week !== 18
  ).map(
    (week) =>
      ({
        week,
        ...NETWORK_KEYS.reduce((acc, network) => ({ ...acc, [network]: [] }), {}),
        // Casting here to avoid an error.
      }) as ViewershipPredictionsRowEntry
  );
  const averageEntries: ViewershipPredictionsAverageEntry[] = [];
  Object.entries(viewershipPredictionsRaw).forEach(([networkKey, predictionEntries]) => {
    Object.entries(predictionEntries.weeks).forEach(([week, gameEntries]) => {
      const viewershipEntry = viewershipIndex.find((entry) => entry.week === Number(week));
      if (!viewershipEntry) {
        throw new Error(`No viewershipEntry found for week ${week}`);
      }
      Object.entries(gameEntries).forEach(([matchup, { viewers, highlight }]) => {
        viewershipEntry[networkKey as NetworkType].push({ matchup, viewers, highlight });
      });
    });

    averageEntries.push({ network: networkKey as NetworkType, ...predictionEntries.avg });
  });

  return { viewership: viewershipIndex, averages: averageEntries };
};

export const getViewershipPredictions: (id: string) => Promise<ViewershipPredictionsIndex> = async (
  id: string
) => {
  const { default: data }: { default: ViewershipPredictionsDataRaw } = await import(
    `../data/schedules/${id}/summary_network_games.json`
  );

  return processViewershipPredictionsDataRaw(data);
};

export const getConstraints: (id: string) => Promise<ConstraintEntry[]> = async (id: string) => {
  const { default: data }: { default: any } = await import(
    `../data/schedules/${id}/constraints.json`
  );
  return Object.values(data) as ConstraintEntry[];
};

export const getViewershipDisplayValue: (value: unknown | number | string) => string = (value) =>
  // Nine Zeroes for Billions
  Math.abs(Number(value)) >= 1.0e9
    ? `${(Math.abs(Number(value)) / 1.0e9).toFixed(2)}B`
    : // Six Zeroes for Millions
      Math.abs(Number(value)) >= 1.0e6
      ? `${(Math.abs(Number(value)) / 1.0e6).toFixed(2)}M`
      : // Three Zeroes for Thousands
        Math.abs(Number(value)) >= 1.0e3
        ? `${(Math.abs(Number(value)) / 1.0e3).toFixed(2)}K`
        : `${Math.abs(Number(value))}`;

export const getWeekSummary: (id: string) => Promise<WeekSummaryIndex> = async (id: string) => {
  const { default: data }: { default: WeekSummaryIndex } = await import(
    `../data/schedules/${id}/summary_weeks.json`
  );
  return data;
};

export const weekdaySorter = (a: string, b: string) =>
  DAYS_IN_ORDER.indexOf(a) - DAYS_IN_ORDER.indexOf(b);

export const slotSorter = (a: string, b: string) =>
  SLOTS_IN_ORDER.indexOf(a) - SLOTS_IN_ORDER.indexOf(b);

export const getStringifiedConstraint = (entry: ConstraintEntry) => {
  switch (entry.type) {
    case ConstraintType.MATCHUP_GUARANTEE:
      return `${entry.params.matchup} ${entry.params.inclusive ? 'plays' : 'does not play'} on ${entry.params.network.sort().join(', ')}${entry.params.holiday_slot ? ` during ${HolidaySlotTranslations[entry.params.holiday_slot]}` : ''}${entry.params.week.includes('All') ? '' : ` on week ${entry.params.week.sort((a, b) => Number(a) - Number(b)).join(', ')}`}`;
    case ConstraintType.STADIUM_BLOCK:
      // eslint-disable-next-line no-case-declarations
      const allSelected = entry.params.slot.includes(ConstraintSlot.ALL);
      // eslint-disable-next-line no-case-declarations
      const slotText = `${
        allSelected
          ? ''
          : ` during ${entry.params.slot
              .sort(slotSorter)
              .map((slot) => ConstraintSlotTranslations[slot])
              .join(', ')}`
      }`;
      return `${entry.params.team} stadium is not available${slotText} on week ${entry.params.week}`;

    case ConstraintType.TEAM_GUARANTEE:
      return `${entry.params.team} plays ${entry.params.min_appearances}${entry.params.min_appearances === entry.params.max_appearances ? '' : `-${entry.params.max_appearances}`} time(s) on ${entry.params.network.join(', ')}${entry.params.holiday_slot ? ` during ${HolidaySlotTranslations[entry.params.holiday_slot]}` : ''}${entry.params.week.includes('All') ? '' : ` on week ${entry.params.week.sort((a, b) => Number(a) - Number(b)).join(', ')}`}`;
    case ConstraintType.BYE_GUARANTEE:
      return `${entry.params.team} ${entry.params.inclusive ? 'has' : 'does not have'} a bye week on week(s) ${entry.params.week.sort((a, b) => Number(a) - Number(b)).join(', ')}`;
    case ConstraintType.HOME_AWAY_GUARANTEE:
      return `${entry.params.team.join(' or ')} plays ${entry.params.home_away === 'home' ? 'at Home' : 'Away'} ${entry.params.min_appearances}${entry.params.min_appearances === entry.params.max_appearances ? '' : `-${entry.params.max_appearances}`} time(s)${entry.params.holiday_slot ? ` during ${HolidaySlotTranslations[entry.params.holiday_slot]}` : ''}${entry.params.network.includes('All') ? '' : ` on ${entry.params.network.join(', ')}`}${entry.params.week.includes('All') ? '' : ` in week(s) ${entry.params.week.sort((a, b) => Number(a) - Number(b)).join(', ')}`}`;
    case ConstraintType.PRIMETIME_MIN_MAX:
      return `${entry.params.team.join(', ')} plays ${entry.params.min_appearances}${entry.params.min_appearances === entry.params.max_appearances ? '' : `-${entry.params.max_appearances}`} time(s)${entry.params.network.includes('All') ? '' : ` on ${entry.params.network.join(', ')}`}`;
    default:
      return 'Unknown Constraint';
  }
};

export const removeDuplicateConstraints = (constraints: ConstraintEntry[]) => {
  const constraintMap = new Map<string, ConstraintEntry>();
  constraints.forEach((constraint) => {
    const key = getStringifiedConstraint(constraint);
    if (!constraintMap.has(key)) {
      constraintMap.set(key, constraint);
    }
  });
  return Array.from(constraintMap.values());
};

export const getAnalysisTableRows: (analysisIndex: AnalysisIndex) => AnalysisTableRow[] = (
  analysisIndex
) => {
  const rows = [];

  const gamesAfterByeWeek = analysisIndex.games_after_bye_week;
  const gamesAfterByeWeekRows = Object.entries(gamesAfterByeWeek).map(([team, { away, home }]) => ({
    section: 'Games After Byes',
    description: `${team} (${home} home, ${away} away)`,
  }));
  rows.push(...gamesAfterByeWeekRows);

  const unfavorableRestDisparity = analysisIndex.unfavorable_rest_disparity;
  const unfavorableRestDisparityRows = unfavorableRestDisparity.map(
    ({ team, favorable, unfavorable }) => ({
      section: 'Unfavorable Rest Disparity > 2',
      description: `${team} (${favorable} favorable, ${unfavorable} unfavorable)`,
    })
  );
  rows.push(...unfavorableRestDisparityRows);

  const twoAwayToStart = analysisIndex.two_away_to_start;
  const twoAwayToStartRows = Object.entries(twoAwayToStart).map(([team, games]) => ({
    section: '2 Away to Start',
    description: `${team}: ${games.join(', ')}`,
  }));
  rows.push(...twoAwayToStartRows);

  const twoAwayToFinish = analysisIndex.two_away_to_finish;
  const twoAwayToFinishRows = Object.entries(twoAwayToFinish).map(([team, games]) => ({
    section: '2 Away to Finish',
    description: `${team}: ${games.join(', ')}`,
  }));
  rows.push(...twoAwayToFinishRows);

  const finishThreeOfFourAway = analysisIndex.finish_three_of_four_away;
  const finishThreeOfFourAwayRows = Object.entries(finishThreeOfFourAway).map(([team, games]) => ({
    section: 'Finish 3 of 4 Away',
    description: `${team}: ${games.join(', ')}`,
  }));
  rows.push(...finishThreeOfFourAwayRows);

  const threeGameRoadTrip = analysisIndex.three_game_road_trip;
  const threeGameRoadTripRows = Object.entries(threeGameRoadTrip).map(([team, games]) => ({
    section: '3 Game Road Trip',
    description: `${team}: ${games.join(', ')}`,
  }));
  rows.push(...threeGameRoadTripRows);

  const roadAfterRoadMondayGame = analysisIndex.road_after_road_monday;
  const roadAfterRoadMondayGameRows = Object.entries(roadAfterRoadMondayGame).map(
    ([team, games]) => ({
      section: 'Road After Road Monday Game',
      description: `${team}: ${games.join(', ')}`,
    })
  );
  rows.push(...roadAfterRoadMondayGameRows);

  const awayBeforeHomeThursdayFriday = analysisIndex.away_before_home_thursday_friday;
  const awayBeforeHomeThursdayFridayRows = Object.entries(awayBeforeHomeThursdayFriday).map(
    ([team, games]) => ({
      section: 'Away Before Home Thursday/Friday Game',
      description: `${team}: ${games.join(', ')}`,
    })
  );
  rows.push(...awayBeforeHomeThursdayFridayRows);

  const divisionGamesCountByWeek = analysisIndex.division_games_count_by_week;
  const divisionGamesCountByWeekRows = Object.entries(divisionGamesCountByWeek)
    .filter(([week]) => Number(week) >= 15 && Number(week) <= 17)
    .map(([week, count]) => ({
      section: 'Division Games 15-17',
      description: `Week ${week}: ${count} division games`,
    }));
  rows.push(...divisionGamesCountByWeekRows);

  // TODO: This is empty, need to fix later.
  // const potentialBalWasConflicts = analysisIndex.potential_bal_was_conflict;
  rows.push({
    section: 'Potential BAL/WAS Conflicts',
    description: 'N/A',
  });

  const primaryDhGameDoesntAirInDc = analysisIndex.primary_dh_game_doesnt_air_in_dc;
  const primaryDhGameDoesntAirInDcRows = primaryDhGameDoesntAirInDc.map(
    ({ week, dc_matchup, doubleheader_matchup }) => ({
      section: "Primary DoubleHeader Game Doesn't Air in DC",
      description: `Week ${week}: ${dc_matchup} airs in DC (in lieu of ${doubleheader_matchup})`,
    })
  );
  rows.push(...primaryDhGameDoesntAirInDcRows);

  rows.push({
    section: 'Network Totals',
    description: `CBS: ${analysisIndex.cbs_total}`,
  });

  rows.push({
    section: 'Network Totals',
    description: `FOX: ${analysisIndex.fox_total}`,
  });

  const dhGameInLa = analysisIndex.dh_game_in_la;
  const dhGameInLaRows = dhGameInLa.map(
    ({ week, network, category, la_matchup, doubleheader_matchup }) => ({
      section: 'DH Game in LA',
      description: `Week ${week} - ${category}:  ${la_matchup} ${la_matchup === doubleheader_matchup ? '' : doubleheader_matchup ? `(instead of ${doubleheader_matchup})` : ''}`,
    })
  );
  rows.push(...dhGameInLaRows);

  const dhLaByCategory = analysisIndex.dh_la_by_category;
  const dhLaByCategoryRows = Object.entries(dhLaByCategory).map(([category, count]) => ({
    section: 'DH Game in LA (Network Totals)',
    description: `${category}: ${count}`,
  }));
  rows.push(...dhLaByCategoryRows);

  const dhGameInNy = analysisIndex.dh_game_in_ny;
  const dhGameInNyRows = dhGameInNy.map(({ week, network, ny_matchup, doubleheader_matchup }) => ({
    section: 'DH Game in NY',
    description: `Week ${week} (${network}): ${ny_matchup} ${doubleheader_matchup === ny_matchup || !doubleheader_matchup ? 'unopposed' : `(against ${doubleheader_matchup})`}`,
  }));
  rows.push(...dhGameInNyRows);

  const dhNyByNetwork = analysisIndex.dh_ny_by_network;
  const dhNyByNetworkRows = Object.entries(dhNyByNetwork).map(([network, count]) => ({
    section: 'DH Game in NY (Network Totals)',
    description: `${network}: ${count}`,
  }));
  rows.push(...dhNyByNetworkRows);

  const nygNyjAtSameTime = analysisIndex.nyg_nyj_at_same_time;
  const nygNyjAtSameTimeRows = Object.entries(nygNyjAtSameTime).map(([week, matchups]) => ({
    section: 'NYG/NYJ playing at same time',
    description: `Week ${week}: ${matchups.join(', ')}`,
  }));
  rows.push(...nygNyjAtSameTimeRows);

  const sfLvAtSameTime = analysisIndex.sf_lv_at_same_time;
  const sfLvAtSameTimeRows = Object.entries(sfLvAtSameTime).map(([week, matchups]) => ({
    section: 'SF/LV playing at same time',
    description: `Week ${week}: ${matchups.join(', ')}`,
  }));
  rows.push(...sfLvAtSameTimeRows);

  const larLacAtSameTime = analysisIndex.lac_lar_at_same_time;
  const larLacAtSameTimeRows = Object.entries(larLacAtSameTime).map(([week, matchups]) => ({
    section: 'LAR/LAC playing at same time',
    description: `Week ${week}: ${matchups.join(', ')}`,
  }));
  rows.push(...larLacAtSameTimeRows);

  rows.push({
    section: 'ESPN Totals',
    description: `ESPN Division Games: ${analysisIndex.espn_division_games_count}`,
  });

  rows.push({
    section: 'ESPN Totals',
    description: `ESPN Zero Appearance Teams (${analysisIndex.teams_by_espn_appearance_count[0].length}): ${analysisIndex.teams_by_espn_appearance_count[0].join(', ')}`,
  });

  rows.push({
    section: 'ESPN Totals',
    description: `ESPN Two Appearance Teams (${analysisIndex.teams_by_espn_appearance_count[2].length}): ${analysisIndex.teams_by_espn_appearance_count[2].join(', ')}`,
  });

  return rows;
};
