import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  CloseButton,
  Flex,
  Group,
  Input,
  Image,
  LoadingOverlay,
  Modal,
  NumberInput,
  ScrollArea,
  SimpleGrid,
  Stack,
  Tabs,
  Text,
  Title,
  rem,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { useDisclosure, useResizeObserver } from '@mantine/hooks';
import { createFileRoute, getRouteApi, useNavigate } from '@tanstack/react-router';
import cx from 'clsx';
import { z } from 'zod';
import scheduleLegend from 'assets/schedule_legend.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDownToBracket,
  faCalendarDay,
  faChartLine,
  faChevronRight,
  faFootballHelmet,
  faFort,
  faGlobe,
  faHandWave,
  faPenToSquare,
  faPeopleArrows,
  faRightLeft,
  faSnooze,
  faSquareList,
  faTvRetro,
} from '@fortawesome/sharp-regular-svg-icons';

import { useAppStore } from 'stores/appStore';
import { downloadXlsx, renameSchedule } from 'api/api';

import { AnalysisTable } from 'components/AnalysisTable';
import ByeThursChart from 'components/ByeThursChart';
import ConstraintsViewer from 'components/ConstraintsViewer';
import MarketsSummary from 'components/MarketsSummary';
import NetworkSummaryChart from 'components/NetworkSummaryChart';
import RestSummaryChart from 'components/RestSummaryChart';
import ScheduleCompare from 'components/ScheduleCompare';
import ScheduleEyeChart from 'components/ScheduleEyeChart';
import ScheduleList from 'components/ScheduleList';
import StadiumBlockChart from 'components/StadiumBlockChart';
import SummaryHighlights from 'components/SummaryHighlights';
import TeamSummary from 'components/TeamSummary';
import ViewershipPredictions from 'components/ViewershipPredictions';
import WeekSummary2 from 'components/WeekSummary2';
import {
  ImperativePanelHandle,
  Panel,
  PanelGroup,
  PanelResizeHandle,
} from 'react-resizable-panels';

import { MARKET_KEYS, TEAM_KEYS, WEEK_KEYS, WeekType } from 'utils/scheduleConsts';

import classes from './schedules.module.css';

const TAB_NAMES = [
  'schedule',
  'analysis',
  'constraints',
  'network_summary',
  'team_summary',
  'week_summary',
  'markets_summary',
  'bye_thurs_summary',
  'stadium_blocks',
  'rest_summary',
  'viewership',
  'compare',
] as const;
type TabNameType = (typeof TAB_NAMES)[number];

function SchedulesPage() {
  const routeSearch = getRouteApi('/_authenticated/schedules').useSearch();
  const { t } = routeSearch;

  const navigate = useNavigate({ from: '/schedules' });

  const [fetchScheduleData, currentScheduleId] = useAppStore((state) => [
    state.fetchScheduleData,
    state.currentScheduleId,
  ]);
  const [currentPercentageThresholds] = useAppStore((state) => [state.currentPercentageThresholds]);

  const [isAppLoading] = useAppStore((state) => [state.isAppLoading, state.setIsAppLoading]);
  const [isScheduleLoading] = useAppStore((state) => [state.isScheduleLoading]);
  const [currentScheduleName] = useAppStore((state) => [state.currentScheduleName]);
  const currentScheduleXlsxUrl = useAppStore((state) => state.currentScheduleXlsxUrl);

  const [newScheduleName, setNewScheduleName] = useState(currentScheduleName);

  const [renameModalOpened, renameModalHandlers] = useDisclosure(false);

  useEffect(() => {
    setNewScheduleName(currentScheduleName);
  }, [currentScheduleName]);

  const renameConfirmButtonRef = useRef<HTMLButtonElement | null>(null);

  // Handle resizing of the schedule panel.
  const [panelSize, setPanelSize] = useState(20);
  const [panelMinSize, setPanelMinSize] = useState(20);
  const [panelMaxSize, setPanelMaxSize] = useState(50);
  const [panelCollapsedSize, setPanelCollapsedSize] = useState(10);
  const PANEL_SIZE_IN_PIXELS = 300;
  const PANEL_MIN_SIZE_IN_PIXELS = 160;
  const PANEL_MAX_SIZE_IN_PIXELS = 600;
  const PANEL_COLLAPSED_SIZE_IN_PIXELS = 20;
  const [ref, rect] = useResizeObserver();
  const [sidebarRef] = useResizeObserver();
  const collapsiblePanelRef = useRef<ImperativePanelHandle | null>(null);
  useLayoutEffect(() => {
    const panelGroupContainer = ref?.current;
    if (!panelGroupContainer) {
      return;
    }
    const panelGroup = panelGroupContainer.querySelector('[data-panel-group]') as HTMLElement;
    const resizeHandle = panelGroup.querySelector('[data-resize-handle]') as HTMLElement;
    const width = panelGroup.offsetWidth - resizeHandle.offsetWidth;
    setPanelSize((PANEL_SIZE_IN_PIXELS / width) * 100);
    setPanelCollapsedSize((PANEL_COLLAPSED_SIZE_IN_PIXELS / width) * 100);
    setPanelMinSize((PANEL_MIN_SIZE_IN_PIXELS / width) * 100);
    setPanelMaxSize((PANEL_MAX_SIZE_IN_PIXELS / width) * 100);
  }, [ref, rect.width]);

  useEffect(() => {
    if (currentScheduleId !== '') {
      fetchScheduleData(currentScheduleId);
      navigate({ search: (prev) => ({ ...prev, s: currentScheduleId }) });
    }
  }, [currentScheduleId]);

  if (isAppLoading) {
    return (
      <Box>
        <LoadingOverlay visible zIndex={1000} overlayProps={{ radius: 'xl', blur: 2 }} />
      </Box>
    );
  }

  return (
    <Flex
      ref={ref}
      h="calc(100dvh - 64px)"
      w="100%"
      style={{
        flexGrow: 0,
      }}
    >
      <Modal
        opened={renameModalOpened}
        size="sm"
        onClose={() => {
          renameModalHandlers.close();
        }}
        title={
          <Text fw={700} size="lg">
            Rename schedule
          </Text>
        }
      >
        <Stack>
          <Text size="sm">Choose a new name for this schedule.</Text>
          <Input
            placeholder="Enter schedule name"
            value={newScheduleName}
            onChange={(e) => setNewScheduleName(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                renameConfirmButtonRef.current?.click();
              }
            }}
          />
          <Flex>
            <Button
              ref={renameConfirmButtonRef}
              size="sm"
              ml="auto"
              onClick={async () => {
                try {
                  await renameSchedule(currentScheduleId, newScheduleName);
                  await useAppStore.getState().fetchBuildsList();
                  renameModalHandlers.close();
                } catch (error: any) {
                  // Start with a generic error and title.
                  let errorTitle = 'Error';
                  let errorMsg =
                    'An error has occurred. If this happens again, please contact support.';

                  if (
                    error?.response?.status === 400 &&
                    error?.response?.data?.name?.[0] ===
                      'nfl scheduler results with this name already exists.'
                  ) {
                    // A build collision is detected.
                    errorTitle = 'Error: Schedule name exists';
                    errorMsg =
                      'A schedule with this name already exists. Please choose a different name.';
                  }

                  // Open an error modal.
                  modals.openConfirmModal({
                    title: errorTitle,
                    children: <Text size="sm">{errorMsg}</Text>,
                    onConfirm: () => {
                      modals.closeAll();
                    },
                    labels: { confirm: 'Continue', cancel: '' },
                    cancelProps: { display: 'none' },
                  });
                }
              }}
            >
              Rename
            </Button>
          </Flex>
        </Stack>
      </Modal>
      <PanelGroup direction="horizontal" style={{ flexGrow: 0, overflow: 'hidden' }}>
        <Panel
          className={cx({
            [classes.collapsed]: collapsiblePanelRef?.current?.isCollapsed() || false,
          })}
          ref={collapsiblePanelRef}
          defaultSize={panelSize}
          collapsible
          collapsedSize={panelCollapsedSize}
          minSize={panelMinSize}
          maxSize={panelMaxSize}
          style={{
            // maxWidth: PANEL_SIZE_IN_PIXELS,
            backgroundColor: 'var(--mantine-color-gray-0)',
            borderRight:
              'calc(0.0625rem * var(--mantine-scale)) solid var(--_app-shell-border-color)',
          }}
        >
          <div ref={sidebarRef} style={{ width: '100%', height: '100%' }}>
            <Box className={classes.hiddenWhenCollapsed} pb={58}>
              <Group justify="space-between" align="start" p="xs" pb={0}>
                <Title order={4} py="xs">
                  Schedules
                </Title>
                <CloseButton
                  size="xs"
                  onClick={() => {
                    const panel = collapsiblePanelRef.current;
                    if (panel) {
                      panel.collapse();
                    }
                  }}
                />
              </Group>
              <ScheduleList />
            </Box>
            <Box
              className={classes.shownWhenCollapsed}
              onClick={() => {
                const panel = collapsiblePanelRef.current;
                if (panel) {
                  panel.expand();
                }
              }}
            >
              <FontAwesomeIcon
                icon={faChevronRight}
                size="sm"
                style={{
                  paddingTop: '12px',
                }}
              />
            </Box>
          </div>
        </Panel>
        <PanelResizeHandle />
        <Panel
          style={{
            overflow: 'hidden',
            display: 'block',
            position: 'relative',
          }}
        >
          <LoadingOverlay
            visible={isScheduleLoading}
            zIndex={1000}
            loaderProps={{ style: { position: 'relative' } }}
            h="100%"
            w="100%"
            overlayProps={{ blur: 1 }}
          />
          <Flex
            flex={1}
            direction="column"
            gap={0}
            w="100%"
            h="100%"
            style={{ overflow: 'hidden' }}
          >
            <Group m="md" gap="xs" className={classes.titleGroup}>
              <Title order={3}>{currentScheduleName}</Title>
              <FontAwesomeIcon
                title="Rename schedule"
                className={classes.editIcon}
                icon={faPenToSquare}
                onClick={() => {
                  setNewScheduleName(currentScheduleName);
                  renameModalHandlers.open();
                }}
              />
              <Button
                component="span"
                onClick={() => {
                  downloadXlsx(currentScheduleId, currentScheduleName, currentScheduleXlsxUrl);
                }}
                leftSection={<FontAwesomeIcon icon={faArrowDownToBracket} />}
                variant="default"
                size="xs"
              >
                Download XLSX
              </Button>
            </Group>
            <ScrollArea type="auto" w="100%" h="100%">
              <Tabs
                p="xs"
                variant="outline"
                radius="xs"
                value={t || 'schedule'}
                onChange={(value) => {
                  const nextTab = (value as TabNameType) || 'schedule';
                  if (nextTab === 'schedule') {
                    navigate({ search: (prev) => ({ s: prev.s }) });
                  } else {
                    navigate({ search: (prev) => ({ s: prev.s, t: nextTab }) });
                  }
                }}
                flex="1"
                miw={1300}
                w="100%"
                keepMounted={false}
              >
                <Tabs.List>
                  <Tabs.Tab
                    value="schedule"
                    leftSection={
                      <FontAwesomeIcon
                        style={{ width: rem(12), height: rem(12) }}
                        icon={faCalendarDay}
                      />
                    }
                  >
                    Eye Chart
                  </Tabs.Tab>
                  <Tabs.Tab
                    value="analysis"
                    leftSection={
                      <FontAwesomeIcon
                        style={{ width: rem(12), height: rem(12) }}
                        icon={faChartLine}
                      />
                    }
                  >
                    Analysis
                  </Tabs.Tab>
                  {/* <Tabs.Tab
                    value="constraints"
                    leftSection={
                      <FontAwesomeIcon style={{ width: rem(12), height: rem(12) }} icon={faFlask} />
                    }
                  >
                    Constraints
                  </Tabs.Tab> */}
                  <Tabs.Tab
                    value="network_summary"
                    leftSection={
                      <FontAwesomeIcon style={{ width: rem(12), height: rem(12) }} icon={faGlobe} />
                    }
                  >
                    Network
                  </Tabs.Tab>
                  <Tabs.Tab
                    value="stadium_blocks"
                    leftSection={
                      <FontAwesomeIcon style={{ width: rem(12), height: rem(12) }} icon={faFort} />
                    }
                  >
                    Stadium Blocks
                  </Tabs.Tab>
                  <Tabs.Tab
                    value="team_summary"
                    leftSection={
                      <FontAwesomeIcon
                        style={{ width: rem(12), height: rem(12) }}
                        icon={faFootballHelmet}
                      />
                    }
                  >
                    Team
                  </Tabs.Tab>
                  <Tabs.Tab
                    value="week_summary"
                    leftSection={
                      <FontAwesomeIcon
                        style={{ width: rem(12), height: rem(12) }}
                        icon={faSquareList}
                      />
                    }
                  >
                    Weeks
                  </Tabs.Tab>
                  <Tabs.Tab
                    value="bye_thurs_summary"
                    leftSection={
                      <FontAwesomeIcon
                        style={{ width: rem(12), height: rem(12) }}
                        icon={faHandWave}
                      />
                    }
                  >
                    Byes&Thurs
                  </Tabs.Tab>
                  <Tabs.Tab
                    value="rest_summary"
                    leftSection={
                      <FontAwesomeIcon
                        style={{ width: rem(12), height: rem(12) }}
                        icon={faSnooze}
                      />
                    }
                  >
                    Rest
                  </Tabs.Tab>
                  <Tabs.Tab
                    value="markets_summary"
                    leftSection={
                      <FontAwesomeIcon
                        style={{ width: rem(12), height: rem(12) }}
                        icon={faPeopleArrows}
                      />
                    }
                  >
                    Markets
                  </Tabs.Tab>
                  <Tabs.Tab
                    value="viewership"
                    leftSection={
                      <FontAwesomeIcon
                        style={{ width: rem(12), height: rem(12) }}
                        icon={faTvRetro}
                      />
                    }
                  >
                    Viewers
                  </Tabs.Tab>
                  <Tabs.Tab
                    value="compare"
                    leftSection={
                      <FontAwesomeIcon
                        style={{ width: rem(12), height: rem(12) }}
                        icon={faRightLeft}
                      />
                    }
                  >
                    Compare
                  </Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value="schedule" p="xs">
                  <Stack gap="sm">
                    <ScheduleEyeChart />
                    <Box w="100%" style={{ display: 'flex', justifyContent: 'center' }}>
                      <Image src={scheduleLegend} alt="Schedule Legend" w="1000" fit="contain" />
                    </Box>
                    <SummaryHighlights />
                  </Stack>
                </Tabs.Panel>
                <Tabs.Panel value="analysis" p="xs">
                  <AnalysisTable />
                </Tabs.Panel>
                <Tabs.Panel value="constraints" p="xs">
                  <Stack gap="xs">
                    <Title order={5} py="xs">
                      Minimum Percentage Change
                    </Title>
                    <SimpleGrid cols={5} spacing={0} verticalSpacing="sm" w={1000}>
                      {currentPercentageThresholds &&
                        Object.entries(currentPercentageThresholds).map(([key, value]) => (
                          <NumberInput
                            variant="unstyled"
                            disabled
                            hideControls
                            key={key}
                            classNames={{
                              root: classes.percentageThresholdInputWrapper,
                              input: classes.percentageThresholdInput,
                            }}
                            size="xs"
                            label={key}
                            style={{ width: rem(160) }}
                            value={value}
                            suffix="%"
                            radius="sm"
                          />
                        ))}
                    </SimpleGrid>
                    <Title order={5} pt="lg">
                      Dynamic Constraints
                    </Title>
                    <ConstraintsViewer />
                  </Stack>
                </Tabs.Panel>
                <Tabs.Panel value="network_summary" p="xs">
                  <NetworkSummaryChart />
                </Tabs.Panel>
                <Tabs.Panel value="stadium_blocks" p="xs">
                  <StadiumBlockChart />
                </Tabs.Panel>
                <Tabs.Panel value="team_summary" p="xs">
                  <TeamSummary />
                </Tabs.Panel>
                <Tabs.Panel value="week_summary" p="xs">
                  <WeekSummary2 />
                </Tabs.Panel>
                <Tabs.Panel value="bye_thurs_summary" p="xs">
                  <ByeThursChart />
                </Tabs.Panel>
                <Tabs.Panel value="rest_summary" p="xs">
                  <RestSummaryChart />
                </Tabs.Panel>
                <Tabs.Panel value="markets_summary" p="xs">
                  <MarketsSummary />
                </Tabs.Panel>
                <Tabs.Panel value="viewership" p="xs">
                  <ViewershipPredictions />
                </Tabs.Panel>
                <Tabs.Panel value="compare" p="xs">
                  <ScheduleCompare />
                </Tabs.Panel>
              </Tabs>
            </ScrollArea>
          </Flex>
        </Panel>
      </PanelGroup>
    </Flex>
  );
}

const schedulesSearchSchema = z.object({
  // current schedule
  s: z.string().catch('').optional(),
  // tab
  t: z.enum(TAB_NAMES).catch('schedule').optional(),
  // Week in weeks summary
  w: z
    .number()
    .refine((val) => WEEK_KEYS.includes(val as WeekType))
    .catch(1)
    .optional(),
  // Team in Team Summary
  team: z.enum(TEAM_KEYS).catch('ATL').optional(),
  // Compared schedules
  c: z.array(z.string()).catch([]).optional(),
  // Market
  m: z.enum(MARKET_KEYS).catch('New York').optional(),
});

// type SchedulesSearch = z.infer<typeof schedulesSearchSchema>;

export const Route = createFileRoute('/_authenticated/schedules')({
  component: SchedulesPage,
  validateSearch: (search) => schedulesSearchSchema.parse(search),
});

// type SchedulesSearch = {
//   t?: TabNameType;
//   w?: typeof WEEK_KEYS_AS_STR[number];
//   team?: TeamType;
// };
// export const Route = createFileRoute('/_authenticated/schedules')({
//   component: SchedulesPage,
//   validateSearch: (search: Record<string, unknown>): SchedulesSearch => ({
//     t: (search.t as TabNames) || 'schedule',
//     w: (search.w as WeekType) || undefined,
//     team: (search.team as TeamType) || undefined,
//   }),
// });
