import { Group, Select, Stack, Table, Text } from '@mantine/core';
import { useEffect, useState } from 'react';

import { getRouteApi, useNavigate } from '@tanstack/react-router';
import { useAppStore } from 'stores/appStore';
import { WEEK_KEYS, WeekSummaryEntry } from 'utils/scheduleConsts';
import { getViewershipDisplayValue } from 'utils/scheduleUtils';

export function WeekSummary2() {
  const routeSearch = getRouteApi('/_authenticated/schedules').useSearch();
  const [selectedWeek, setSelectedWeek] = useState(routeSearch.w ? routeSearch.w.toString() : '1');
  const [data, setData] = useState<WeekSummaryEntry[]>([]);
  const currentWeekSummary = useAppStore((state) => state.currentWeekSummary);
  const navigate = useNavigate({ from: '/schedules' });

  useEffect(() => {
    if (currentWeekSummary !== null) {
      const newData = currentWeekSummary[Number(selectedWeek) as keyof typeof currentWeekSummary];
      setData(newData);
    }
    if (selectedWeek === '1') {
      navigate({ to: '/schedules', search: (prev) => ({ s: prev.s, t: 'week_summary' }) });
    } else {
      navigate({
        to: '/schedules',
        search: (prev) => ({ s: prev.s, t: 'week_summary', w: Number(selectedWeek) }),
      });
    }
  }, [currentWeekSummary, selectedWeek]);

  type SummaryEntry = {
    header: string;
    total: number;
    teams: string[];
  };

  const summaryEntries: SummaryEntry[] = [];

  data.forEach((dataEntry) => {
    const newHeader =
      `${dataEntry.network} ${dataEntry['day.of.week']} ${dataEntry['time.of.day']}`.trim();
    if (!summaryEntries.find((entry) => entry.header === newHeader)) {
      summaryEntries.push({
        header: newHeader,
        total: dataEntry.rating,
        teams: [dataEntry.matchup],
      });
    } else {
      summaryEntries.find((entry) => entry.header === newHeader)!.total += dataEntry.rating;
      summaryEntries.find((entry) => entry.header === newHeader)!.teams.push(dataEntry.matchup);
    }
  });

  const maxEntries = Math.max(...summaryEntries.map((entry) => entry.teams.length));

  return (
    <Stack gap="xs">
      <Group align="center">
        <Text>Week</Text>
        <Select
          checkIconPosition="right"
          value={selectedWeek}
          allowDeselect={false}
          onChange={(value) => setSelectedWeek(value as string)}
          data={WEEK_KEYS.filter((week) => week !== 18).map((week) => `${week}`)}
          searchable
          w="90px"
        />
      </Group>
      <div>
        <Table withTableBorder striped highlightOnHover>
          <Table.Thead>
            <Table.Tr>
              {summaryEntries.map((entry) => (
                <Table.Th key={entry.header}>
                  {entry.header} ({entry.teams.length})
                </Table.Th>
              ))}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {Array.from({ length: maxEntries }, (_, index) => (
              <Table.Tr key={index}>
                {summaryEntries.map((entry) => (
                  <Table.Td key={entry.header}>{entry.teams[index]}</Table.Td>
                ))}
              </Table.Tr>
            ))}
            <Table.Tr>
              {summaryEntries.map((entry) => (
                <Table.Td key={entry.header} fw={700}>
                  {getViewershipDisplayValue(entry.total)}
                </Table.Td>
              ))}
            </Table.Tr>
          </Table.Tbody>
        </Table>
      </div>
    </Stack>
  );
}
